import React, { useState, useContext, useEffect, useCallback } from "react"
import { io } from "socket.io-client"
import Layout from "../components/root/Layout"
import LoadingSpinner from "../components/root/LoadingSpinner"
import { useAuth } from "./auth-context"
import database from "./database.json"
const SocketContext = React.createContext()
let databaseRef = database
const autoAudit3000 = socket => {
  // Remove the first element from the array.
  const item = databaseRef.shift()
  setTimeout(() => {
    socket.emit("audit", { url: item.domain, name: item.domain, public: true })
    console.log(item.domain)
    autoAudit3000(socket) // Call function again.
  }, 20000) // Iterate every second.
}

export const SocketProvider = ({ anonymous, ...props }) => {
  const { user } = useAuth()
  const [socket, setSocket] = useState(null)
  const [connected, setConnected] = useState(false)
  const [audits, setAudits] = useState({})
  const [userInfo, setUserInfo] = useState({})
  const [load, setInitialLoad] = useState(anonymous ? true : false)
  const [publicSites, setPublicSites] = useState([])

  useEffect(() => {
    try {
      const socket = io(
        !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? "ws://localhost:3000"
          : "https://v2api.digitalfootprint.earth",
        {
          auth: { token: user?.accessToken, anonymous },
        }
      )
      setSocket(socket)
    } catch (error) {
      console.log(error)
    }
  }, [user])
  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        setConnected(true)
      })

      socket.on("disconnect", () => {
        setConnected(false)
      })
      socket.on("user-update", data => {
        setUserInfo(data)
      })
      socket.on("audit-update", data => {
        const newAudits = { ...audits }
        if (!newAudits[data.uid]) {
          newAudits[data.uid] = data
        } else {
          newAudits[data.uid] = { ...newAudits[data.uid], ...data }
        }
        setAudits(newAudits)
      })
      socket.on("audits", data => {
        setInitialLoad(true)
        setAudits(data)
      })
      socket.on("public-sites", data => {
        console.log(data)
        setPublicSites(data)
      })
    }
  }, [socket, setAudits, audits])

  // useEffect(() => {
  //   if (socket) {
  //     autoAudit3000(socket)
  //   }
  // }, [socket])

  const submitAudit = useCallback(
    (url, name, isPublic) => {
      if (socket) {
        socket.emit("audit", { url, name, public: isPublic })
      }
    },
    [socket]
  )

  const retrieveAudit = useCallback(
    id => {
      return audits[id]
    },
    [audits]
  )

  if (!load) {
    return (
      <Layout>
        <LoadingSpinner text="Pulling your audits" />
      </Layout>
    )
  }

  return (
    <SocketContext.Provider
      value={{
        connected,
        socket,
        audits,
        userInfo,
        submitAudit,
        load,
        retrieveAudit,
        publicSites,
      }}
      {...props}
    />
  )
}

export const useSocket = () => useContext(SocketContext)

export default SocketContext
