import * as React from "react"

import { Link } from "gatsby"

const PrimaryButtonLink = ({ className = "", children, to }) => {
  return (
    <Link
      to={to}
      className={`${className} flex items-center justify-center px-4 py-2 font-bold rounded-md shadow-sm text-gray-900 bg-yellow-500 sm:px-8`}
    >
      {children}
    </Link>
  )
}

export default PrimaryButtonLink
