module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/samlarsen-disney/Developer/DF/client/src/templates/docs-template.js"},"gatsbyRemarkPlugins":[{"resolve":"/Users/samlarsen-disney/Developer/DF/client/node_modules/gatsby-remark-autolink-headers","id":"43abc9e5-eb93-509f-b2a4-cf5c31c50d82","name":"gatsby-remark-autolink-headers","version":"5.9.0","modulePath":"/Users/samlarsen-disney/Developer/DF/client/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"icon":false,"removeAccents":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]}],"extensions":[".md",".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/samlarsen-disney/Developer/DF/client","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"removeAccents":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DigitalFootprint.earth","short_name":"DF","start_url":"/","background_color":"#1e293b","theme_color":"#1e293b","display":"standalone","icon":"src/images/Logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8b16d75775705b96ee77cff8c299485a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
