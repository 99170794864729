import * as React from "react"

import { Link } from "gatsby"

import Logo from "./Logo"

const LogoWithText = ({ user, isLanding }) => {
  return (
    <div className="flex flex-row gap-x-6">
      <Link
        to={user ? "/dashboard" : "/"}
        className={`flex items-center font-medium group ${
          isLanding ? "text-white" : "text-primary"
        } hover:text-yellow-400 space-x-1`}
      >
        <Logo className="h-6 w-6" alt="Toast" />
        <h1 className="flex text-2xl">
          Digital<span className="font-bold">Footprint</span>
        </h1>
      </Link>
    </div>
  )
}

export default LogoWithText
