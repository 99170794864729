exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mdx-slug-index-js": () => import("./../../../src/pages/{Mdx.slug}/index.js" /* webpackChunkName: "component---src-pages-mdx-slug-index-js" */),
  "component---src-pages-new-js": () => import("./../../../src/pages/new.js" /* webpackChunkName: "component---src-pages-new-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-private-story-[uid]-js": () => import("./../../../src/pages/private-story/[uid].js" /* webpackChunkName: "component---src-pages-private-story-[uid]-js" */),
  "component---src-pages-report-[uid]-js": () => import("./../../../src/pages/report/[uid].js" /* webpackChunkName: "component---src-pages-report-[uid]-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-story-[uid]-js": () => import("./../../../src/pages/story/[uid].js" /* webpackChunkName: "component---src-pages-story-[uid]-js" */),
  "component---src-pages-story-index-js": () => import("./../../../src/pages/story/index.js" /* webpackChunkName: "component---src-pages-story-index-js" */)
}

