import * as React from "react"

import { Link } from "gatsby"

import DarkModeToggle from "../buttons/DarkModeToggle"
import PrimaryButtonLink from "../buttons/PrimaryButtonLink"

const DesktopMenu = ({
  linksToShow,
  isLanding,
  user,
  logout,
  loginRequired,
}) => {
  return (
    <div className="hidden md:flex gap-x-4">
      {linksToShow.map(({ name, path }) => (
        <Link
          className={`${
            isLanding ? "text-white" : "text-link"
          } flex items-center justify-center font-bold px-2 py-2`}
          to={path}
          key={path}
        >
          {name}
        </Link>
      ))}

      {!user && (
        <Link
          to="/login"
          className={`flex items-center justify-center px-2 py-2 font-bold rounded-md shadow-sm ${
            isLanding ? "text-white" : "text-primary"
          }`}
        >
          Sign Up
        </Link>
      )}
      {loginRequired ? (
        <button
          className="btn-secondary items-center flex space-x-1"
          onClick={logout}
        >
          Logout
        </button>
      ) : (
        <PrimaryButtonLink to={user ? "/dashboard" : "/login"}>
          {user ? "Dashboard" : "Login"}
        </PrimaryButtonLink>
      )}
      <DarkModeToggle isLanding={isLanding} />
    </div>
  )
}

export default DesktopMenu
