import * as React from "react"

import { MDXProvider } from "@mdx-js/react"

import "./src/styles/global.css"
import { wrapPageElement as RootWrapper } from "./wrapPageElement"
import ThemeContextProvider from "./src/context/theme-context"

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeContextProvider>
      <MDXProvider>
        <RootWrapper element={element} props={props} />
      </MDXProvider>
    </ThemeContextProvider>
  )
}
