import * as React from "react"

import { Link } from "gatsby"
import { MenuAlt3Icon, XIcon } from "@heroicons/react/solid"

import PrimaryButtonLink from "../buttons/PrimaryButtonLink"
import DarkModeToggle from "../buttons/DarkModeToggle"
import useOutsideAlerter from "../../hooks/useOutsideAlerter"

const MobileMenu = ({
  linksToShow,
  isLanding,
  user,
  logout,
  loginRequired,
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const menuRef = React.useRef(null)
  useOutsideAlerter(menuRef, () => setMenuOpen(false))

  return (
    <div className="relative flex md:hidden gap-x-4 align-center items-center">
      <DarkModeToggle isLanding={isLanding} />
      {menuOpen ? (
        <button onClick={() => setMenuOpen(false)}>
          <XIcon
            className={`${isLanding ? "text-white" : "text-priamry"} w-8 h-8`}
          />
        </button>
      ) : (
        <button onClick={() => setMenuOpen(true)}>
          <MenuAlt3Icon
            className={`${isLanding ? "text-white" : "text-priamry"} w-8 h-8`}
          />
        </button>
      )}

      {menuOpen && (
        <div className="bg-white absolute z-50 top-16 right-0 w-64 rounded-lg shadow-md">
          {linksToShow.map(({ name, path }) => (
            <Link
              className="text-gray-900 flex flex-col items-center justify-center font-bold px-2 py-2 hover:underline"
              to={path}
              key={path}
            >
              {name}
            </Link>
          ))}

          <div className="flex flex-row align-center items-center justify-center px-2 py-2 gap-x-4">
            {!user && (
              <Link
                to="/login"
                className="flex flex-col items-center justify-center px-2 py-2 font-bold rounded-md text-gray-900"
              >
                Sign Up
              </Link>
            )}
            {loginRequired ? (
              <button
                className="btn-secondary items-center flex space-x-1"
                onClick={logout}
              >
                Logout
              </button>
            ) : (
              <PrimaryButtonLink to={user ? "/dashboard" : "/login"}>
                {user ? "Dashboard" : "Login"}
              </PrimaryButtonLink>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default MobileMenu
