import firebase from "firebase/compat/app"
import "firebase/compat/auth"

export default function useFirebase() {
  const config = {
    apiKey: "AIzaSyDMdU8SIclkKER8Xsu2FelXP4Mcpk40Ags",
    authDomain: "cdn-hatch.firebaseapp.com",
    projectId: "cdn-hatch",
    storageBucket: "cdn-hatch.appspot.com",
    messagingSenderId: "114024957027",
    appId: "1:114024957027:web:b1d3eb76854fede596a456",
  }
  firebase.initializeApp(config)
  return firebase
}
