import * as React from "react"

import Seo from "./SEO"
import Header from "./Header"
import Footer from "./Footer"

const Layout = ({
  title,
  description,
  children,
  mainClassName = "px-6 max-w-7xl mx-auto",
  isLanding,
}) => {
  return (
    <div className="min-h-screen overflow-hidden">
      <div className="bg-primary text-primary">
        <Seo title={title} description={description} />
        {isLanding ? (
          <div className="relative">
            <div className="absolute top-0 left-0 w-screen">
              <Header isLanding={isLanding} />
            </div>
            <main className={mainClassName}>{children}</main>
          </div>
        ) : (
          <div>
            <Header />
            <main className={mainClassName}>{children}</main>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
