import * as React from "react"

import { useAuth } from "../../context/auth-context"

import LogoWithText from "../../assets/LogoWithText"
import DesktopMenu from "../navigation/DesktopMenu"
import MobileMenu from "../navigation/MobileMenu"

const Header = ({ isLanding }) => {
  const publicLinks = [
    {
      name: "Pricing",
      path: "/pricing",
    },
    {
      name: "Explore",
      path: "/story",
    },
    {
      name: "Stats",
      path: "/stats",
    },
  ]

  const privateLinks = [
    {
      name: "Documentation",
      path: "/documentation",
    },
  ]

  const { user, logout, loginRequired } = useAuth()
  const linksToShow = loginRequired ? privateLinks : publicLinks

  return (
    <div className="relative z-50 flex justify-between items-center max-w-7xl mx-auto px-6 py-6 md:pt-6">
      <LogoWithText user={user} isLanding={isLanding} />

      <DesktopMenu
        linksToShow={linksToShow}
        isLanding={isLanding}
        user={user}
        logout={logout}
        loginRequired={loginRequired}
      />
      <MobileMenu
        linksToShow={linksToShow}
        isLanding={isLanding}
        user={user}
        logout={logout}
        loginRequired={loginRequired}
      />
    </div>
  )
}

export default Header

{
  /* <Link
        to={user ? "/dashboard" : "/"}
        className="flex items-center font-medium group text-primary hover:text-yellow-400 space-x-1"
      >
        <Logo className="h-5 w-5" alt="Toast" />
        <h1 className="text-2xl">
          Digital<span className="font-bold">Footprint</span>
        </h1>
      </Link>
      <div className="flex items-center space-x-6 text-lg">
        {linksToShow.map(({ name, path }) => (
          <Link className="text-link" to={path} key={path}>
            {name}
          </Link>
        ))}

        <DarkModeToggle />

        {loginRequired ? (
          <button
            className="btn-secondary items-center flex space-x-1"
            onClick={logout}
          >
            Logout
          </button>
        ) : (
          <PrimaryButtonLink to={user ? "/dashboard" : "/login"}>
            {user ? "Dashboard" : "Login"}
          </PrimaryButtonLink>
        )}
      </div>
    </div> */
}
