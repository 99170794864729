import * as React from "react"

import Logo from "../../assets/Logo"

const Footer = () => {
  return (
    <footer className="bg-primary" aria-labelledby="footer-heading">
      <div className="max-w-7xl mx-auto text-center flex flex-col md:flex-row justify-between items-center gap-y-4 px-6 py-6">
        <div className="flex space-x-3">
          <Logo className="h-6 w-6 text-gray-500" />
          <p className="font-bold text-gray-400 dark:text-gray-300 text-lg">
            DigitalFootprint.earth
          </p>
        </div>
        <p className="text-gray-500">
          Translating Digital Footprints into Real World Effects.
        </p>
      </div>
    </footer>
  )
}

export default Footer
