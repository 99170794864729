import React from "react"
import { LazyMotion, domMax } from "framer-motion"
import { AuthProvider } from "./src/context/auth-context"
import { SocketProvider } from "./src/context/socket-context"

const publicRoutes = [
  "/",
  "/login",
  "/pricing",
  "/documentation",
  "/product",
  "/new",
  "/story",
  "/stats"
]

const socketRoutes = ["/dashboard", "/new", "/search", "/report", "/story", "/private-story"]
const anonymousSocketRoutes = ["/story"]

const AuthWrapper = ({ children, location: { pathname } }) => {
  return (
    <AuthProvider
      loginRequired={
        publicRoutes.find(element => element.includes(pathname)) === undefined
      }
    >
      {children}
    </AuthProvider>
  )
}

const SocketWrapper = ({ children, location: { pathname } }) => {
  // console.log(socketRoutes.find(element => element.includes(pathname)))
  if (socketRoutes.find(element => pathname.includes(element))) {
    return (
      <SocketProvider anonymous={anonymousSocketRoutes.includes(pathname)}>
        {children}
      </SocketProvider>
    )
  } else {
    return children
  }
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <LazyMotion strict features={domMax}>
      <AuthWrapper {...props}>
        <SocketWrapper {...props}>{element}</SocketWrapper>
      </AuthWrapper>
    </LazyMotion>
  )
}
