import * as React from "react"

import useLocalStorage from "../hooks/useLocalStorage"

const ThemeContext = React.createContext({})

const ThemeContextProvider = ({ children, ...props }) => {
  // Set the default theme to dark-mode
  const [theme, setTheme] = useLocalStorage("theme", null)

  const switchTheme = newTheme => {
    const root = document.body
    root.classList.remove("light")
    root.classList.remove("dark")
    root.classList.add(newTheme)
    setTheme(newTheme)
  }

  return (
    <ThemeContext.Provider value={{ theme, switchTheme }} {...props}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useThemeContext = () => {
  return React.useContext(ThemeContext)
}

export default ThemeContextProvider
